import React, { useState, useEffect } from "react";
import api from "../request";
import Moment from "react-moment";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

const RecentPolls = props => {
  const [polls, setPolls] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    api
      .get("polls")
      .then(res => {
        setPolls(res.data);
        setLoaded(true);
      })
      .catch(e => {
        console.log(e);
        setMsg("Failed to load polls!");
      });
  }, []);

  return (
    <div className="recentpolls">
      <Helmet>
        <title>Recent Polls</title>
      </Helmet>
      <div style={{ display: "flex", marginTop: "15px" }}>
        <Link to="/" style={{ marginLeft: "auto" }}>
          Make a poll
        </Link>
      </div>
      <h1>Polls</h1>
      {loaded ? (
        polls.length > 0 ? (
          polls.map(poll => (
            <div className="polllist" key={poll.uuid}>
              <Link to={poll.uuid}>{poll.question}</Link>
              <span>
                Created <Moment fromNow>{poll.createdAt}</Moment>
              </span>
            </div>
          ))
        ) : (
          <h3>No public polls found!</h3>
        )
      ) : (
        <h3>{!msg ? "Loading..." : msg}</h3>
      )}
    </div>
  );
};

export default RecentPolls;
