import React, { useState, useRef, useEffect } from "react";
import OptionInput from "./optionInput";
import api from "../request";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Home = props => {
  const [options, setOptions] = useState(["", ""]);
  const [question, setQuestion] = useState("");
  const [visibility, setVisibility] = useState("private");
  const [submitting, setSubmitting] = useState(false);
  const [msg, setMsg] = useState("");

  const questionInp = useRef(null);

  const history = useHistory();

  useEffect(() => {
    questionInp.current.focus();
  }, []);

  const handleOptionChange = event => {
    let optionsCopy = [...options];

    optionsCopy[event.target.name] = event.target.value;

    if (optionsCopy[optionsCopy.length - 1] !== "") {
      optionsCopy.push("");
    }

    setOptions(optionsCopy);
    setMsg("");
  };

  const submitPoll = () => {
    setSubmitting(true);
    setMsg("");

    api
      .post("createpoll", { question, options, visibility })
      .then(res => {
        if (!res.data.error) {
          const uuid = res.data.uuid;
          history.push("/" + uuid);
        } else {
          setMsg(res.data.error);
          setSubmitting(false);
        }
      })
      .catch(e => {
        console.log(e);
        setMsg("We encountered an error!");
        setSubmitting(false);
      });
  };

  return (
    <div className="home">
      <Helmet>
        <title>Polls</title>
      </Helmet>
      <div style={{ display: "flex", marginTop: "15px" }}>
        <Link to="/polls" style={{ marginLeft: "auto" }}>
          View recent polls
        </Link>
      </div>
      <h1>Polls</h1>
      <input
        className="newquestion"
        type="text"
        placeholder="Question"
        autoComplete="off"
        onChange={e => {
          setQuestion(e.target.value);
        }}
        value={question}
        ref={questionInp}
      />
      <div className="newoptions">
        <ul>
          {options.map((option, index) => (
            <li key={index}>
              <OptionInput
                i={index}
                value={option}
                handleChange={handleOptionChange}
              />
            </li>
          ))}
        </ul>
      </div>
      <select
        name="visibility"
        style={{ marginLeft: "auto" }}
        onChange={e => setVisibility(e.target.value)}
      >
        <option value="private">Private</option>
        <option value="public">Public</option>
      </select>
      <button
        onClick={submitPoll}
        className="accentbutton createpoll"
        disabled={submitting ? true : false}
      >
        {!submitting ? "Create Poll" : "creating..."}
      </button>
      <span className="msg">{msg}</span>
    </div>
  );
};

export default Home;
