import React, { useState, useEffect, useCallback } from "react";
import api from "../request";
import Moment from "react-moment";
import abbreviate from "number-abbreviate";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Poll = props => {
  const [poll, setPoll] = useState({});
  const [vote, setVote] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [msg, setMsg] = useState("");

  const getPoll = useCallback(() => {
    api
      .get("poll/" + props.match.params.uuid)
      .then(res => {
        setPoll(res.data);
        setLoaded(true);
      })
      .catch(e => {
        console.log(e);
        setMsg("Failed to load poll!");
      });
  }, [props.match.params.uuid]);

  useEffect(() => {
    getPoll();
  }, [getPoll]);

  useEffect(() => {
    if (poll.vote) {
      const updateInterval = setInterval(getPoll, 5000);

      return () => {
        clearInterval(updateInterval);
      };
    }
  }, [poll.vote, getPoll]);

  const submitVote = () => {
    setSubmitting(true);

    api
      .post("poll/" + props.match.params.uuid + "/vote", { vote })
      .then(res => {
        if (!res.data.error) {
          setSubmitting(false);
          setPoll(res.data);
        } else {
          setMsg(res.data.error);
        }
      })
      .catch(e => console.log(e));
  };

  const handleVoteChange = event => {
    setVote(event.target.value);
  };

  const getVotePercentage = opt => {
    const total = poll.options.reduce((a, b) => {
      return a + b.votes;
    }, 0);

    return ((opt.votes / total) * 100).toFixed(1) + "%";
  };

  const getWinningOption = () => {
    let options = [];
    for (const i of poll.options) {
      if (
        (options.length > 0 && i.votes > options[0].votes) ||
        options.length === 0
      ) {
        options = [i];
      } else if (i.votes === options[0].votes) {
        options.push(i);
      }
    }
    return options.map(o => o.id);
  };

  const OptionResult = props => {
    const perc = getVotePercentage(props.option);
    const winningOption = getWinningOption();
    return (
      <div className="optionresult">
        <div
          className={
            winningOption.includes(props.option.id)
              ? "optionresultbar winningbar"
              : "optionresultbar"
          }
          style={{ width: perc }}
        ></div>
        <span className="optionresulttext">
          <span className="optionresultnum">
            {abbreviate(props.option.votes, 2)}
          </span>
          <span>{props.option.value}</span>
        </span>
      </div>
    );
  };

  return (
    <div className="poll">
      {loaded ? (
        <React.Fragment>
          <Helmet>
            <title>{poll.question + " | Polls"}</title>
          </Helmet>
          <h2>{poll.question}</h2>
          {!poll.vote ? (
            <React.Fragment>
              <div className="polloptions">
                {poll.options ? (
                  poll.options.map((option, index) => (
                    <div key={index}>
                      <input
                        id={option.id}
                        type="radio"
                        name="poll"
                        value={option.id}
                        onChange={handleVoteChange}
                      />
                      <label htmlFor={option.id}>{option.value}</label>
                    </div>
                  ))
                ) : (
                  <h2>Poll not found!</h2>
                )}
              </div>
              <button
                className="accentbutton submitvote"
                onClick={submitVote}
                disabled={!vote || submitting ? true : false}
              >
                {!submitting ? "Vote" : "voting..."}
              </button>
              <span>{msg}</span>
            </React.Fragment>
          ) : (
            <div>
              {poll.options.map((option, index) => (
                <OptionResult key={index} option={option} />
              ))}
            </div>
          )}
          <div className="footer">
            <Link to="/">Make a poll</Link>
            <span className="timestamp" title={new Date(poll.createdAt)}>
              Created <Moment fromNow>{poll.createdAt}</Moment>
            </span>
          </div>
        </React.Fragment>
      ) : (
        <h3>{!msg ? "Loading..." : msg}</h3>
      )}
    </div>
  );
};

export default Poll;
