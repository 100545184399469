import axios from "axios";

const api = axios.create({
  baseURL:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? `http://localhost:5200/api/`
      : `http://api.polls.jwb.cloud/api/`,
  withCredentials: true
});

export default api;
