import React from "react";

const OptionInput = props => {
  return (
    <input
      type="text"
      placeholder={"Option " + (props.i + 1)}
      value={props.value}
      onChange={props.handleChange}
      name={props.i}
      autoComplete="off"
    />
  );
};

export default OptionInput;
