import { Switch, Route } from "react-router-dom";
import Home from "./components/home";
import Poll from "./components/poll";
import RecentPolls from "./components/recentPolls";

const App = () => {
  return (
    <Switch>
      <Route exact path="/" render={props => <Home {...props} />} />
      <Route exact path="/polls" render={props => <RecentPolls {...props} />} />
      <Route exact path="/:uuid" render={props => <Poll {...props} />} />
    </Switch>
  );
};

export default App;
